/* these styles are used in the markdown rendering.
These should correspond to the styles here:
https://m2.material.io/design/typography/the-type-system.html#type-scale
so everything matches throughout our app
*/

.markdown h2 {
    font-family: "Avenir", "Avenir Next LT Pro", "Montserrat", "sans-serif";
    font-weight: 400;
    font-size: 2.55em;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.markdown h3 {
    font-family: "Avenir", "Avenir Next LT Pro", "Montserrat", "sans-serif";
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

/* paragraphs */
.markdown p {
    color: #f1f1f1;
    font-family: "Avenir", "Avenir Next LT Pro", "Montserrat", "sans-serif";
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

/* links */
.markdown a {
    color: #77C5FF;
    text-decoration: none
}

.markdown img {
    width: 90%;
    margin: auto;
    display: block;
    clip-path: inset(1px);
}

.markdown img[alt=inspection-reliability] {
    width: 80%;
}

.markdown img[alt=electrolyte_visualisation],
.markdown img[alt=battery-failure],
.markdown img[alt=ct-defects-marginal] {
    width: 75%;
}

.markdown img[alt=x-ray-artifacts],
.markdown img[alt=electrolyte_BYD],
.markdown img[alt=metal-artifacts],
.markdown img[alt=cone-beam-artifacts],
.markdown img[alt=x-ray_exposure] {
    width: 60%;
}

.markdown img[alt=ct-defects-critical],
.markdown img[alt=separator-radial],
.markdown img[alt=metallic-contaminant],
.markdown img[alt=inspection-slice],
.markdown img[alt=breathing],
.markdown img[alt=buckling] {
    width: 50%;
}

.markdown img[alt=electrolyte_cell] {
    width: 15%;
}

.markdown img[alt=electrode_overhang_dashboard] {
    border: 3px solid #969696;
}

.markdown table,
th,
td {
    border: 1px solid #969696;
    border-collapse: collapse;
    padding: 6px;
}


.markdown code {
    font-family: "Roboto Mono", "Courier New", monospace;
    font-size: 1em;
    line-height: 1.6;
    letter-spacing: 0.00938em;
    padding: 0.2em
}
